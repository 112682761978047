import {
  Directive,
  type DoCheck,
  Injector,
  Input,
  computed,
  effect,
  inject,
  input,
  signal,
  HostListener,
} from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { hlm } from '@spartan-ng/ui-core';
import { BrnFormFieldControl } from '@spartan-ng/ui-form-field-brain';
import { ErrorStateMatcher, ErrorStateTracker } from '@spartan-ng/ui-forms-brain';
import { type VariantProps, cva } from 'class-variance-authority';
import type { ClassValue } from 'clsx';

import { getSubmitted } from '@lib/ui-formfield-helm/src/lib/submitted.utils';

export const inputVariants = cva(
  'flex w-full rounded-md border font-normal border-input bg-transparent text-sm ring-offset-background file:border-0 file:text-foreground file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible-input-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        default: 'h-10 py-2 px-4',
        sm: 'h-9 px-3',
        lg: 'h-11 px-8',
      },
      error: {
        true: 'text-destructive border-destructive focus-visible:ring-destructive',
        false: '',
      },
    },
    defaultVariants: {
      size: 'default',
      error: false,
    },
  }
);
type InputVariants = VariantProps<typeof inputVariants>;

@Directive({
  selector: '[btoInput]',
  standalone: true,
  host: {
    '[class]': '_computedClass()',
  },
  providers: [
    {
      provide: BrnFormFieldControl,
      useExisting: HlmInputDirective,
    },
  ],
})
export class HlmInputDirective implements BrnFormFieldControl, DoCheck {
  private readonly _size = signal<InputVariants['size']>('default');
  @Input()
  set size(value: InputVariants['size']) {
    this._size.set(value);
  }

  private readonly _error = signal<InputVariants['error']>(false);
  @Input()
  set error(value: InputVariants['error']) {
    this._error.set(value);
  }

  public readonly userClass = input<ClassValue>('', { alias: 'class' });
  protected _computedClass = computed(() =>
    hlm(inputVariants({ size: this._size(), error: this._error() }), this.userClass())
  );

  private injector = inject(Injector);

  ngControl: NgControl | null = this.injector.get(NgControl, null);

  errorStateTracker: ErrorStateTracker;

  private defaultErrorStateMatcher = inject(ErrorStateMatcher);
  private parentForm = inject(NgForm, { optional: true });
  private parentFormGroup = inject(FormGroupDirective, { optional: true });
  private formSubmitted = getSubmitted(this.parentForm, this.parentFormGroup);

  errorState = computed(() => {
    const error = this.errorStateTracker.errorState() || false;
    if (this.formSubmitted() && this.ngControl?.touched) {
      return error;
    }
    return false;
  });

  constructor() {
    this.errorStateTracker = new ErrorStateTracker(
      this.defaultErrorStateMatcher,
      this.ngControl,
      this.parentFormGroup,
      this.parentForm
    );

    effect(
      () => {
        if (this.ngControl) {
          this.error = this.errorState();
        }
      },
      { allowSignalWrites: true }
    );
  }

  ngDoCheck() {
    this.errorStateTracker.updateErrorState();
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    event.stopPropagation();
  }
}

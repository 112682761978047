import { Signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormGroupDirective, NgForm } from '@angular/forms';
import { map, of, startWith } from 'rxjs';

export const getSubmitted = (form?: NgForm | null, formGroup?: FormGroupDirective | null): Signal<boolean> => {
  const formOrFormGroup = form || formGroup;
  return toSignal(
    formOrFormGroup?.ngSubmit.asObservable().pipe(startWith(false), map(Boolean)) || of(false)
  ) as Signal<boolean>;
};

import { Component, type Signal, computed, contentChild, effect, input, inject } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { TranslocoPipe } from '@jsverse/transloco';
import { filter, map, switchMap } from 'rxjs';

import { FirstErrorPipe } from '@lib/ui-formfield-helm/src/lib/first-error.pipe';
import { getSubmitted } from '@lib/ui-formfield-helm/src/lib/submitted.utils';

import { HlmErrorDirective } from './hlm-error.directive';

@Component({
  selector: 'bto-form-field',
  template: `
    <ng-content></ng-content>

    @if (isSubmitted() && hasError()) {
      @if (formControl()?.errors | firstError; as error) {
        <bto-error class="text-left">{{
          errorTranslocoKey() + '.' + error.name | transloco: { value: error.value }
        }}</bto-error>
      }
    }
  `,
  standalone: true,
  imports: [FirstErrorPipe, HlmErrorDirective, TranslocoPipe],
})
export class HlmFormFieldComponent {
  private parentForm = inject(NgForm, { optional: true });
  private parentFormGroup = inject(FormGroupDirective, { optional: true });

  errorTranslocoKey = input<string>();
  hint = input('');
  control = contentChild(NgControl);
  formControl = computed(() => this.control()?.control || null);

  isSubmitted = getSubmitted(this.parentForm, this.parentFormGroup);

  hasError: Signal<boolean | undefined> = toSignal(
    toObservable(this.formControl).pipe(
      filter(Boolean),
      switchMap(control => control.statusChanges),
      map(() => !!this.formControl()?.errors)
    )
  );

  constructor() {
    effect(() => {
      if (!this.control()) {
        throw new Error('hlm-form-field must contain a BrnFormFieldControl.');
      }
    });
  }
}

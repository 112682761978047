import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'firstError',
  standalone: true,
})
export class FirstErrorPipe implements PipeTransform {
  transform(errors: AbstractControl['errors'] | null | undefined): { name: string; value: any } | null {
    if (errors) {
      const firstError = Object.entries(errors)[0];

      if (!firstError) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const [name, value] = firstError;
      return firstError ? { name, value: this.extractActualValue(name, value) } : null;
    }
    return null;
  }

  private extractActualValue(name: string, value: any): string {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (typeof value === 'object') {
      if (name in value) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return value[name] as string;
      }
      return Object.values(value as Record<string, string>)[0];
    }
    return '';
  }
}
